import { b } from "@/b";
import { MEDIA_FRAGMENT, ResponsiveImage } from "@/components/ResponsiveImage";
import { Section } from "@/components/layout/Section";
import { Box, Button, Flex, Grid, Heading, Text } from "@chakra-ui/react";
import { FragmentData } from "@gqlb/core";
import { Link } from "@chakra-ui/next-js";
import { useMemo } from "react";
import { useRouter } from "next/router";

const MealPreviewProduct_Fragment = b.fragment(
  "MealPreviewProduct",
  "Product",
  (b) => [
    b.id(),
    b.name(),
    b.description(),
    b.images((b) => [b.__fragment(MEDIA_FRAGMENT)]),
  ]
);

const MealPreview_Fragment = b.fragment("MealPreview", "Tag", (b) => [
  b.id(),
  b.name(),
  b.products({ limit: 1 }, (b) => [b.__fragment(MealPreviewProduct_Fragment)]),
]);

export const MealTagShowcase_Fragment = b.fragment(
  "MealTagShowcase",
  "Query",
  (b) => [
    b
      .tag({ name: "Breakfast" }, (b) => [b.__fragment(MealPreview_Fragment)])
      .alias("breakfast"),
    b
      .tag({ name: "Chef Special" }, (b) => [
        b.__fragment(MealPreview_Fragment),
      ])
      .alias("lunch"),
    b
      .tag({ name: "Signature Entree" }, (b) => [
        b.__fragment(MealPreview_Fragment),
      ])
      .alias("dinner"),
    b
      .tag({ name: "Dessert" }, (b) => [b.__fragment(MealPreview_Fragment)])
      .alias("dessert"),
  ]
);

export const MealTagShowcase: React.FC<{
  data: FragmentData<typeof MealTagShowcase_Fragment>;
}> = ({ data }) => {
  const router = useRouter();
  const previews = useMemo(() => {
    const previews: Array<{
      id: string;
      name: string;
      product: FragmentData<typeof MealPreviewProduct_Fragment>;
      link: string;
    }> = [];
    if (data.breakfast && data.breakfast.products.length > 0) {
      previews.push({
        id: data.breakfast.id,
        name: data.breakfast.name,
        product: data.breakfast.products[0],
        link: `/menu?tags=${data.breakfast.id}`,
      });
    }

    if (data.lunch && data.lunch.products.length > 0) {
      previews.push({
        id: data.lunch.id,
        name: "Lunch",
        product: data.lunch.products[0],
        link: `/menu`,
      });
    }

    if (data.dinner && data.dinner.products.length > 0) {
      previews.push({
        id: data.dinner.id,
        name: "Dinner",
        product: data.dinner.products[0],
        link: `/menu`,
      });
    }

    if (data.dessert && data.dessert.products.length > 0) {
      previews.push({
        id: data.dessert.id,
        name: data.dessert.name,
        product: data.dessert.products[0],
        link: `/menu?tags=${data.dessert.id}`,
      });
    }

    return previews;
  }, [data]);

  return (
    <Section padded>
      <Flex
        flexDir="row"
        justifyContent={{ base: "center", md: "space-between" }}
        alignItems="center"
      >
        <Box>
          <Heading
            fontSize={{
              base: "2xl",
              md: "4xl",
            }}
            textAlign={{
              base: "center",
              md: "left",
            }}
            fontWeight="semibold"
          >
            Ready to Eat. Farm-to-Table. 100% Gluten-Free.
          </Heading>
          <Text
            maxW="lg"
            textAlign={{
              base: "center",
              md: "left",
            }}
            fontSize={{
              base: "sm",
              md: "md",
            }}
          >
            From sunrise to sweet treats, we&apos;ve got you covered. Our chefs
            carefully create healthy, flavorful recipes to fuel your entire day.
          </Text>
        </Box>
        <Button
          variant="outline"
          colorScheme="black"
          size="md"
          rounded="full"
          p="6"
          fontWeight="regular"
          onClick={() => {
            router.push("/menu");
          }}
          display={{
            base: "none",
            md: "flex",
          }}
        >
          Browse Full Menu &rarr;
        </Button>
      </Flex>
      {previews.length > 0 && (
        <Flex
          flexDir="row"
          gap="8"
          mt="12"
          overflowX="auto"
          justifyContent="space-between"
        >
          {previews.map((tag) => (
            <Link
              key={tag.id}
              alignItems="center"
              flexDir="column"
              minW="auto"
              href={tag.link}
            >
              <ResponsiveImage
                image={tag.product.images[0]}
                objectFit="cover"
                aspectRatio="5/6"
                w={{
                  base: "180px",
                  md: "300px",
                }}
              />
              <Text
                pt="4"
                textDecor="underline"
                textAlign="center"
                fontWeight="semibold"
              >
                {tag.name} &rarr;
              </Text>
            </Link>
          ))}
        </Flex>
      )}
    </Section>
  );
};
