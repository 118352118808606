import { b } from "@/b";
import { BoxProps, Image as ChakraImage, Flex } from "@chakra-ui/react";
import { FragmentData } from "@gqlb/core";
import React, { useEffect, useRef, useState } from "react";

export const MEDIA_FRAGMENT = b.fragment("MediaFragment", "Image", (b) => [
  b.id(),
  b.preview(),
]);

export const ResponsiveImage: React.FC<
  {
    image: FragmentData<typeof MEDIA_FRAGMENT>;
    objectFit: "cover" | "contain";
    forceSize?: number;
    lazy?: boolean;
  } & BoxProps
> = ({ image, objectFit, forceSize, lazy = true, ...props }) => {
  const [activeSrc, setActiveSrc] = useState<string>(
    `/image/${image.id}?size=200&q=75`
  );
  const [maxSize, setMaxSize] = useState<number | undefined>(undefined);

  useEffect(() => {
    async function loadImage() {
      if (maxSize === undefined) {
        return;
      }
      const rounding = maxSize > 400 ? 100 : 50;
      const size = Math.max(
        Math.min(forceSize ?? Math.round(maxSize / rounding) * rounding, 4800),
        200
      );

      try {
        const url = `/image/${image.id}?size=${size}&q=75`;
        const img = new Image();
        img.src = url;
        await img.decode();
        setActiveSrc(url);
        return;
      } catch {}
    }
    loadImage();
  }, [forceSize, image, maxSize]);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    setMaxSize(containerRef.current.clientWidth * window.devicePixelRatio);
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      const viewedImageSize = width * window.devicePixelRatio;
      setMaxSize(viewedImageSize);
    });
    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, setMaxSize]);

  return (
    <Flex ref={containerRef} pos="relative" overflow="hidden" {...props}>
      <ChakraImage
        alt=""
        display="block"
        pos="absolute"
        inset="0"
        bottom="0"
        h="full"
        w="full"
        src={image.preview}
        objectFit={objectFit}
        filter="blur(8px)"
      />
      <ChakraImage
        alt=""
        display="block"
        pos="absolute"
        inset="0"
        bottom="0"
        h="full"
        w="full"
        src={activeSrc}
        objectFit={objectFit}
        loading={lazy ? "lazy" : "eager"}
      />
    </Flex>
  );
};
